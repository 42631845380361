import { Injectable } from "@angular/core";
import { IClubDetailsFacade } from "../../shared/interfaces/club-details-facade.interface";
import { ClubService } from "./data-access/services/club.service";
import { Observable } from "rxjs";
import { ClubMgr } from "./data-access/dto/club-mgr.model";

@Injectable({
  providedIn: 'root'
})
export class ClubFacade implements IClubDetailsFacade {

  constructor(private clubService: ClubService) {}

  getClubById(id: string): Observable<ClubMgr> {
    return this.clubService.getById(id);
  }
}

import { inject, Injectable } from '@angular/core';
import { map, Observable, of } from "rxjs";
import { ClubEditGQL, ClubMgrGQL } from "src/app/shared/graphql"
import { mapApolloMutationResultDataOrThrowOnError } from "../../../../shared/graphql/graphql-utils";
import { ClubEditManagerInput } from "../models/club-edit-manger.input";
import { ClubMgr } from "../dto/club-mgr.model";

@Injectable({
  providedIn: 'root'
})
export class ClubService {

  constructor() { }

  #clubMgrGQL = inject(ClubMgrGQL);
  #clubEditGQL = inject(ClubEditGQL);

  getById(id: string): Observable<ClubMgr> {
    return this.#clubMgrGQL.fetch({ clubId: id }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map((data) => data.clubMgr as ClubMgr)
    );
  }

  edit(data: ClubEditManagerInput): Observable<ClubMgr> {
    return this.#clubEditGQL.mutate({ data }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map((data) => data.clubEditManager as ClubMgr)
    );
  }
}
